import { getCookieValue, rootDomain } from '../utils/cookie';

export const useCookieUserType = () => {
  const init = () => {
    if (!window) {
      return;
    }

    const userType = getCookieValue('UserType');

    if (userType && userType === 'DepN') {
      return;
    }

    const domain = rootDomain(document.location.hostname);

    window.document.cookie = `UserType=Dep0;Max-Age=63158400;Domain=${domain};Path=/`;
  };

  return {
    init,
  };
};
