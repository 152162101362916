import { defineStore } from 'pinia';
import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { imgParser } from '@/utils/imgParser';

export interface CurrencyMenuItemInterface {
  crypto: boolean; // null;
  currencyCode: string; // "EUR";
  currencySymbol: string; // "€";
  id: number; // 1;
  position: number; // 1;
  stageOnly: boolean; // false
  defaultForCountries: string[]; // ["IT", "DE"]
  exludedCountries: string[]; // ["IT", "DE"]
  precision: number; // 2
  currencyCodeShort?: string; // "mBTC"
  precisionShort?: number; // 4
  multiplierShort?: number; // 1000
  currencyIcon: StrapiImageInterface;
  availableAmounts?: string; // '20,50,100,200,500'
  preselectedAmount?: number; // 20

  createdAt: Date; // "2020-10-12T11:56:13.101Z";
  updatedAt: Date; // "2020-10-12T11:56:22.509Z";
  publishedAt: Date; // "2020-10-12T11:56:21.541Z";
}

export const useStoreCurrencyMenu = defineStore('currencyMenu', {
  state: () => ({
    currencies: [] as CurrencyMenuItemInterface[],
    requestPromise: null as Promise<any> | null,
  }),
  getters: {
    currenciesGetter(state): CurrencyMenuItemInterface[] {
      return state.currencies.sort((a, b) => a.position - b.position);
    },

    cryptoCurrencyCodes(): string[] {
      return this.currenciesGetter.filter((c) => c.crypto).map((c) => c.currencyCode);
    },
  },
  actions: {
    isCryptoCurrency(currencyCode: string) {
      return !!this.cryptoCurrencyCodes.includes(currencyCode);
    },
    SAVE_CURRENCIES_FROM_STRAPI(data: any) {
      this.currencies = data
        .map((c) => {
          // console.log(c.currencyIcon)
          return {
            id: Number(c.id),
            crypto: !!c.crypto,
            currencyCode: c.currencyCode,
            currencySymbol: c.currencySymbol,
            position: Number(c.position),
            stageOnly: !!c.stageOnly,
            defaultForCountries: (c.countriesDefault || '')
              .split(',')
              .filter((code: string | null) => !!code)
              .map((code) => String(code).toUpperCase()),
            exludedCountries: (c.countriesExcluded || '')
              .split(',')
              .filter((code: string | null) => !!code)
              .map((code) => String(code).toUpperCase()),
            precision: Number(c.position),
            currencyCodeShort: c.currencyCodeShort,
            currencyIcon: imgParser(c.currencyIcon),
            availableAmounts: c.availableAmounts,
            preselectedAmount: Number(c.preselectedAmount),

            createdAt: new Date(c.createdAt),
            updatedAt: new Date(c.updatedAt),
            publishedAt: new Date(c.publishedAt),
          };
        })
        .sort((a, b) => a.position - b.position);
    },
  },
});
