import { defineStore } from 'pinia';
import imgUrlReplacer from '@/utils/imgUrlReplacer';

export interface LandingPageInterface {
  filterCountries: any;
  filterStags: any;
  head: any;
  path: string;
  theme: string;
  template: any;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  groupToAddOnReg: string;
}

export const useStoreLanding = defineStore('landings', {
  state: () => ({
    pages: [] as LandingPageInterface[],
  }),
  getters: {
    pathes(): string[] {
      return this.pages.flatMap(({ path }) => path).filter((p) => p);
    },
    pageData(): LandingPageInterface[] {
      return this.pages;
    },
    isLoaded(): boolean {
      return !!this.pathes.length;
    },
  },
  actions: {
    ADD_PAGES_FROM_STRAPI(data) {
      imgUrlReplacer(data);

      const newPages = data.map((p) => ({
        path: p.path,
        theme: p.theme,
        groupToAddOnReg: p.groupToAddOnReg || '',
        head: p.head,
        filterCountries: p.filterCountries,
        filterStags: p.filterStags,
        createdAt: new Date(p.createdAt),
        updatedAt: new Date(p.updatedAt),
        publishedAt: new Date(p.publishedAt),
        template: p.template[0],
      }));

      this.pages = newPages;
    },
  },
});
