/**
 * Method for transforming country/stag params to array
 *
 * @returns true - if finded
 * @returns false - if not finded
 * @returns null - if string is not defined
 */
export const parseAndFind = (
  value: string | null | undefined,
  search?: string | number
): boolean | null => {
  if (!value) {
    return null;
  }

  return value
    .split(',')
    .map((v) => v.toUpperCase())
    .includes(String(search));
};
