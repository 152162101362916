export enum PagesEnum {
  index = 'index',
}

export enum LangsEnum {
  en = 'en',
  de = 'de',
  fr = 'fr',
  ru = 'ru',
  it = 'it',
  pl = 'pl',
  at = 'de',
  ch = 'de',
}

export type LangsType = 'en' | 'de' | 'fr' | 'ru' | 'it' | 'pl';
