import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { loadPlayerInfo, updatePlayerGroups } from '@/services/ssPlayer';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import {
  ISsserGroupsUpdateRequestInterface,
  SsPlayerResponseInterface,
} from '~/types/softswiss/ssPlayer.response.interface';

export const useStoreSsPlayer = defineStore('ssPlayer', {
  state: () => ({
    playerInfo: {} as SsPlayerResponseInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    isPlayerAuthorised(state): boolean {
      return !!state.playerInfo.id;
    },
  },
  actions: {
    async loadPlayerInfo() {
      const languageStore = useStoreLanguage();
      this.status = LoadingStatusEnum.loading;

      try {
        const lang = languageStore.languageGetter;
        const playerInfo = await loadPlayerInfo(lang);

        this.playerInfo = { ...this.playerInfo, ...playerInfo };
        this.status = LoadingStatusEnum.loaded;
      } catch (e) {
        this.status = LoadingStatusEnum.error;
      }
    },
    async updatePlayerGroups(body: ISsserGroupsUpdateRequestInterface): Promise<void> {
      const languageStore = useStoreLanguage();
      try {
        const lang = languageStore.languageGetter;
        const groups: ISsserGroupsUpdateRequestInterface = {};

        if (body.add?.length) {
          groups.add = body.add;
        }

        if (body.remove?.length) {
          groups.remove = body.remove;
        }

        await updatePlayerGroups(groups, lang);
        await this.loadPlayerInfo();
      } catch (e: any) {
        console.error(new Error('update of groups failed!'));
      }
    },
  },
});
