import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { DEFAULT_REDIRECT_HOST } from '@/config/constants';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { SystemHostsStrapiInterface } from '~/types/strapi/systemHosts.strapi.interface';

export const useStoreSystemHosts = defineStore('systemHosts', {
  state: () => ({
    hosts: [] as SystemHostsStrapiInterface[],
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    /**
     * regional domain host
     * @param state of store
     */
    regional(state): string {
      const defaultHost = '7bitcasino.com';

      if (window?.location?.host && state.status === LoadingStatusEnum.loaded) {
        return (
          state.hosts.find((h) => h.host === window.location.host)?.regionalVisibleDomain ||
          defaultHost
        );
      }

      return defaultHost;
    },

    /**
     * geoip host
     * @param state of store
     */
    geo(): string {
      const defaultHost = 'country.7bitcasino13.com';

      if (this.current) {
        return this.current?.geoHost || defaultHost;
      }

      return defaultHost;
    },

    /**
     * redirect host
     * @param state of store
     */
    redirectHost(): string {
      const languageStore = useStoreLanguage();
      const langPrefix = languageStore.langPrefix;
      let redirectHost = DEFAULT_REDIRECT_HOST;

      if (this.current) {
        redirectHost = this.current?.redirectHost;
      }

      return `${redirectHost}${langPrefix}`;
    },
    /**
     * images host
     * @param state of store
     */
    gameImages(): string {
      const defaultHost = '7bitcdn.com';

      if (this.current) {
        return this.current?.gameImagesHost || defaultHost;
      }

      return defaultHost;
    },

    /**
     * CMS host
     * @param state of store
     */
    cms(): string {
      const defaultHost = 'dinamic-content.7bitcasino.com';

      if (this.current) {
        return this.current?.cmsHost || defaultHost;
      }

      return defaultHost;
    },

    /**
     * list of all hosts
     * @param state of store
     */
    list(state): SystemHostsStrapiInterface[] {
      return state.hosts;
    },

    /**
     * current host params
     * @param state of store
     */
    current(state): SystemHostsStrapiInterface | null {
      return state.hosts.find((h) => h?.host.toLowerCase() === window?.location?.host) || null;
    },

    ssHost(): string | undefined {
      return this.current?.ssHost;
    },

    isCurentHostPromo(): boolean {
      return this.current?.hostType === 'promo';
    },

    isCurentHostCasino(): boolean {
      return this.current?.hostType === 'casino';
    },

    /**
     * status of loading
     * @param state of store
     */
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    SAVE_HOSTS_FROM_STRAPI(data: SystemHostsStrapiInterface[]) {
      this.hosts = data.map((item) => ({
        cmsHost: item.cmsHost,
        gameImagesHost: item.gameImagesHost,
        geoHost: item.geoHost,
        host: item.host,
        hostType: item.hostType,
        isStage: item.isStage,
        redirectHost: item.redirectHost,
        regionalVisibleDomain: item.regionalVisibleDomain,
        ssHost: item.ssHost,
      }));
    },
    SET_STATUS(status: LoadingStatusEnum) {
      this.status = status;
    },
  },
});
