import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LandPage from '@/views/landPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:path',
    name: 'LandPage',
    component: LandPage,
  },
  {
    path: '/promo/:path',
    name: 'LandPagePromo',
    component: LandPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
