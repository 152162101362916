import axios from 'axios';

export async function updateLocale(lang: string, headers?: Record<string, string>) {
  const response = await axios.patch(
    '/api/player/update_locale',
    { locale: lang },
    {
      withCredentials: true,
      headers: {
        Accept: 'application/vnd.s.v1+json',
        'accept-language': lang,
        'Content-Type': 'application/json;charset=UTF-8',
        ...(headers || {}),
      },
    }
  );
  return response.data;
}
