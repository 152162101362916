import { defineStore } from 'pinia';
import { useStoreAuthStrapi } from '../strapi/auth';
import { useBonusesStore } from '../strapi/bonuses';
import { useStoreButtonTitles } from '../strapi/buttonTitles';
import { useStoreComponents } from '../strapi/components';
import { useStoreCurrencyMenu } from '../strapi/currencyMenu';
import { useStoreFooter } from '../strapi/footer';
import { useStoreLanding } from '../strapi/landing';
import { useStoreLandingComponents } from '../strapi/landingsComponents';
import { useStoreRaces } from '../strapi/races';
import { useStoreSnippet } from '../strapi/snippet';
import { useStoreSystemHosts } from '../strapi/systemHosts';
import { useStoreLanguage } from './language';
import { LangsType } from '@/types/pages.types';
import { strapi } from '~/types/transport/strapi';

export const useStoreStrapi = defineStore('strapi', {
  state: () => ({}),
  actions: {
    async loadSystemHostsData(): Promise<boolean> {
      const systemHostsStore = useStoreSystemHosts();
      const languageStore = useStoreLanguage();
      const locale = languageStore.languageGetter as string;
      const response = await strapi.get(
        'system-hosts',
        `locale=${locale}&pagination[pageSize]=1000`
      );

      if (response) {
        systemHostsStore.SAVE_HOSTS_FROM_STRAPI(response.data);
        return true;
      } else {
        return false;
      }
    },

    async loadLandingsData(path): Promise<boolean> {
      const landingStore = useStoreLanding();
      const languageStore = useStoreLanguage();
      const currencyMenuStore = useStoreCurrencyMenu();
      try {
        const locale = languageStore.languageGetter as string;
        const [landingsResponse, currencyMenusResponse] = await Promise.all([
          strapi.get(
            'landings',
            `locale=${locale}&populate[0]=head&populate[1]=filterCountries&populate[2]=filterStags&populate[3]=template.paymentsLogo&populate[4]=template.partnersLogo&populate[5]=template.partnersLogoTop&populate[6]=template.logo&populate[7]=template.bannerDesktop&populate[8]=template.bannerMobile&populate[9]=template.modalBannerDesktop&populate[10]=template.modalBannerMobile&populate[11]=template.currency&populate[12]=template.welcomePackBlock&populate[13]=template.welcomePackBlock.cardIcon&populate[14]=template.promotionsBlock&populate[15]=template.tournamentsBlock&populate[16]=template.bannerDesktopBack&populate[17]=template.buyCryptoBlock&populate[18]=template.buyCryptoBlock.bannerFront&populate[19]=template.buyCryptoBlock.bannerBack&populate[20]=template.welcomePackBlock.cardIconFront1&populate[21]=template.welcomePackBlock.cardIconFront2&populate[22]=template.welcomePackBlock.cardIconFront3&populate[23]=template.welcomePackBlock.cardIconFront4&populate[24]=template.welcomePackBlock.cardIconBack1&populate[25]=template.welcomePackBlock.cardIconBack2&populate[26]=template.welcomePackBlock.cardIconBack3&populate[27]=template.welcomePackBlock.cardIconBack4&filters[path][$eq]=${path}`
          ),
          strapi.get('currency-menus', 'populate[0]=currencyIcon'),
        ]);
        languageStore.setLocale(locale as LangsType);

        if (landingsResponse && currencyMenusResponse) {
          landingStore.ADD_PAGES_FROM_STRAPI(landingsResponse.data);
          currencyMenuStore.SAVE_CURRENCIES_FROM_STRAPI(currencyMenusResponse.data);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading landing data:', error);
        return false;
      }
    },

    async loadBonusesAndRacesData(): Promise<boolean> {
      const bonusesStore = useBonusesStore();
      const racesStore = useStoreRaces();
      const languageStore = useStoreLanguage();
      try {
        const locale = languageStore.languageGetter as string;

        // Perform all three API requests
        const [bonusesResponse, racesResponse, bonusesTabsResponse] = await Promise.all([
          strapi.get(
            'bonuses',
            `locale=${locale}&populate[0]=bonusType&populate[1]=bannerCardFront&populate[2]=bannerCardBack`
          ),
          strapi.get(
            'races',
            `locale=${locale}&populate[0]=bannerCardBack&populate[1]=bannerCardFront&populate[2]=bannerSmallMobile&populate[3]=bannerSmall`
          ),
          strapi.get('bonuses-tabs', `locale=${locale}`),
        ]);

        if (bonusesResponse && racesResponse && bonusesTabsResponse) {
          bonusesStore.SAVE_DATA_FROM_STRAPI({
            ...bonusesResponse,
            bonusesTabs: bonusesTabsResponse.data,
          });
          racesStore.SAVE_DATA_FROM_STRAPI(racesResponse.data);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading bonuses and races data:', error);
        return false;
      }
    },

    async loadCommonData(): Promise<boolean> {
      const footerStore = useStoreFooter();
      const landingComponentsStore = useStoreLandingComponents();
      const buttonTitlesStore = useStoreButtonTitles();
      const componentsStore = useStoreComponents();
      const authStrapiStore = useStoreAuthStrapi();
      const snippetStore = useStoreSnippet();
      const languageStore = useStoreLanguage();
      try {
        const locale = languageStore.languageGetter as string;

        const [
          componentResponse,
          buttonTitleResponse,
          snippetResponse,
          authModalResponse,
          footerResponse,
          landComponentsResponse,
        ] = await Promise.all([
          strapi.get(
            'component',
            `locale=${locale}&populate[races][fields][0]=raceLabelWeeklyTitle&populate[races][fields][1]=raceLabelDailyTitle&populate[races][fields][2]=raceLabelExclusiveTitle&populate[races][fields][3]=raceLabelPartnerTitle&populate[timer][fields][0]=leftTitle&populate[timer][fields][1]=daysTitle&populate[timer][fields][2]=hoursTitle&populate[timer][fields][3]=minutesTitle&populate[timer][fields][4]=secondsTitle`
          ),
          strapi.get('button-title', `locale=${locale}&fields[0]=signup&fields[1]=learnMore`),
          strapi.get('snippet'),
          strapi.get('auth-modal', `locale=${locale}&populate=*`),
          strapi.get(
            'footer',
            `locale=${locale}&populate[0]=logo&populate[1]=informationLink&populate[2]=promotionsLink&populate[3]=gamesLink&populate[4]=helpLink&populate[5]=worldwideLink&populate[6]=europeLink&populate[7]=blogLink&populate[8]=responsibleGamingLink&populate[9]=gamesSuppliedLink&populate[10]=gamesSuppliedLink.img_src&populate[11]=paymentMethodsLink&populate[12]=paymentMethodsLink.img_src&populate[13]=partnersLink&populate[14]=partnersLink.img_src&populate[15]=gamblers&populate[16]=gamblers.img_src&populate[17]=partnersLogo&populate[18]=mirrorFooter&pagination[pageSize]=1000`
          ),
          strapi.get('landing-component', `locale=${locale}&populate[0]=signUpCloseConfirm`),
        ]);

        if (
          componentResponse &&
          buttonTitleResponse &&
          snippetResponse &&
          authModalResponse &&
          footerResponse &&
          landComponentsResponse
        ) {
          componentsStore.SAVE_DATA_FROM_STRAPI(componentResponse);
          buttonTitlesStore.SAVE_DATA_FROM_STRAPI(buttonTitleResponse);
          snippetStore.SAVE_DATA_FROM_STRAPI(snippetResponse.data);
          authStrapiStore.SAVE_DATA_FROM_STRAPI(authModalResponse);
          footerStore.SAVE_DATA_FROM_STRAPI(footerResponse);
          landingComponentsStore.SAVE_DATA_FROM_STRAPI(landComponentsResponse);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading common data:', error);
        return false;
      }
    },
  },
});
