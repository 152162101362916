/**
 * MUTABLE FUNCTION!!! Plugin that change image URL
 * @param obj - object with data, loaded from Strapi
 */

const keyToFind = `url`;
const strapiImgUrl = `https://strapi-7bit-bucket.s3.us-west-1.amazonaws.com/`;
const newUrl = `/cdn/`;

const imgUrlReplacer = (obj: any) => {
  if (!obj) {
    return;
  }

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      imgUrlReplacer(obj[key]);
    } else if (key === keyToFind && obj[key].includes(strapiImgUrl)) {
      obj[key] = obj[key].replace(strapiImgUrl, newUrl);
    }
  });
};

export default imgUrlReplacer;
