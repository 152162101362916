import axios from 'axios';
import { getCookieValue, rootDomain } from '~/utils/cookie';

export const useOneSignalID = () => {
  const init = () => {
    if (!window) {
      return;
    }

    const pushExternalId = window.location.href.match(/pushExternalId=([\w-]+(_[\d\w]+)?)/)?.[1];
    if (pushExternalId) {
      localStorage.setItem('pushExternalId', pushExternalId);
    }
    const mode = window.location.href.match(/mode=([\w-]+(_[\d\w]+)?)/)?.[1];
    if (mode == 'pwa') {
      localStorage.setItem('isPwa', '1');
    }
  };

  return {
    init,
    getPushId: () => localStorage.getItem('pushExternalId'),
    setUserGroup: () => {
      if (localStorage.getItem('pushExternalId')) {
        axios.get(
          'https://app.push.express/api/postback/?id=' +
            localStorage.getItem('pushExternalId') +
            '&audiences=register_1201',
          {
            withCredentials: false,
          }
        );
        axios.get(
          'https://app.push.express/api/postback/?id=' +
            localStorage.getItem('pushExternalId') +
            '&status=reg_1201',
          {
            withCredentials: false,
          }
        );
      }
    },
  };
};
