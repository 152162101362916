export const scriptLoader = (src: string, id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
    }

    const script = document.createElement('script');

    script.onload = function () {
      resolve();
    };

    script.onerror = function (e) {
      reject(e);
    };

    script.id = id;
    script.src = src;

    document.head.appendChild(script);
  });
};
