import { defineStore } from 'pinia';

export const useStoreComponents = defineStore('components', {
  state: () => ({
    timer: {
      leftTitle: '',
      daysTitle: '',
      hoursTitle: '',
      minutesTitle: '',
      secondsTitle: '',
    },
    races: {
      raceLabelWeeklyTitle: '',
      raceLabelDailyTitle: '',
      raceLabelExclusiveTitle: '',
      raceLabelPartnerTitle: '',
    },
  }),
  getters: {
    timerData(state) {
      return state.timer;
    },
    racesData(state) {
      return state.races;
    },
  },
  actions: {
    SAVE_DATA_FROM_STRAPI({ data }) {
      this.races = data.races;
      this.timer = data.timer;
    },
  },
});
