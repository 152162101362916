import { computed } from 'vue';
import { useStoreCountry } from '@/store/client/country';

export function useDataCountryStore() {
  const countryStore = useStoreCountry();

  const country = computed((): string => countryStore.countryGetter);

  const check = () => countryStore.check();

  return {
    country,
    check,
  };
}
