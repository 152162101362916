import { getCookieValue, rootDomain } from '~/utils/cookie';

const SOURCE_STAG_FACT = 'SourceStagFact';

export const useOriginalStag = () => {
  const init = () => {
    if (!window) {
      return;
    }

    const stag = window.location.href.match(/stag=(\d+(_[\d\w]+)?)/)?.[1];
    const currentCookieStag = getCookieValue(SOURCE_STAG_FACT);
    const domain = rootDomain(document.location.hostname);

    if (currentCookieStag) {
      window.document.cookie = `${SOURCE_STAG_FACT}=${currentCookieStag};Max-Age=63158400;Domain=${domain};Path=/`;
    } else if (stag) {
      window.document.cookie = `${SOURCE_STAG_FACT}=${stag};Max-Age=63158400;Domain=${domain};Path=/`;
    } else if (document.referrer) {
      window.document.cookie = `${SOURCE_STAG_FACT}=${document.referrer};Max-Age=63158400;Domain=${domain};Path=/`;
    } else {
      window.document.cookie = `${SOURCE_STAG_FACT}=Direct;Max-Age=63158400;Domain=${domain};Path=/`;
    }

    if (!localStorage.getItem(SOURCE_STAG_FACT)) {
      if (stag) {
        localStorage.setItem(SOURCE_STAG_FACT, stag);
      } else if (document.referrer) {
        localStorage.setItem(SOURCE_STAG_FACT, document.referrer);
      } else {
        localStorage.setItem(SOURCE_STAG_FACT, 'Direct');
      }
    }

    const stagFromLocalStorage = localStorage.getItem(SOURCE_STAG_FACT);

    if (stagFromLocalStorage?.trim()) {
      window.document.cookie = `${SOURCE_STAG_FACT}2=${stagFromLocalStorage};Max-Age=63158400;Domain=${domain};Path=/`;
    }
  };

  return {
    init,
  };
};
