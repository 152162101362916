import { computed } from 'vue';
import { useStoreStrapi } from '@/store/abstract/strapi';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';

export function useStrapiStore() {
  const landingStore = useStoreLanding();
  const systemHostsStore = useStoreSystemHosts();
  const currencyMenuStore = useStoreCurrencyMenu();
  const strapiStore = useStoreStrapi();

  const redirectHost = computed(() => systemHostsStore.redirectHost);

  const ssHost = computed(() => systemHostsStore.ssHost);

  const pathes = computed(() => landingStore.pathes);

  const pageData = computed(() => landingStore.pageData);

  const isLoaded = computed(() => landingStore.isLoaded);

  const currencies = computed(() => currencyMenuStore.currenciesGetter);

  const loadSystemHostsData = () => strapiStore.loadSystemHostsData();

  const loadLandingsData = (path: string) => strapiStore.loadLandingsData(path);

  const loadBonusesAndRacesData = () => strapiStore.loadBonusesAndRacesData();

  const loadCommonData = () => strapiStore.loadCommonData();

  return {
    redirectHost,
    ssHost,
    pathes,
    pageData,
    isLoaded,
    currencies,
    loadSystemHostsData,
    loadLandingsData,
    loadBonusesAndRacesData,
    loadCommonData,
  };
}
