import { computed, ref } from 'vue';
import { useCommonStore } from './useCommonStore';
import { useDataLanguageStore } from './useLanguageStore';
import { useStrapiStore } from './useStrapiStore';
import { CurrencyMenuItemInterface } from '@/store/strapi/currencyMenu';
import { parseAndFind } from '@/utils/parseAndFind';
import { PromoLoaderInterface } from '~/types/promoLoader.interface';

export function useLandingComputed() {
  const strapi = useStrapiStore();
  const common = useCommonStore();
  const language = useDataLanguageStore();

  const isMiraxFrenchLand = computed(
    () =>
      currentPage.value?.template.__component === 'landing-templates.modern2-square' &&
      currentPage.value?.template.isMiraxFrenchLand
  );

  const isGoogleRegTemplate = computed(() =>
    [
      'landing-templates.bonus-hub',
      'landing-templates.modern2-lion',
      'landing-templates.bonus-hub-neon',
    ].includes(currentPage.value?.template.__component)
  );

  const lang = computed(() => language.language.value);

  /**
   * Get promo pages, filtered by included/excluded countries and stag`s
   */
  const filteredPages = computed(() => {
    const pageData = strapi.pageData.value;

    return pageData
      .filter((p) => p.path === common.path.value)
      .filter(
        (p) =>
          !(
            parseAndFind(p.filterCountries.includedCoutries, common.userCountry.value) === false ||
            parseAndFind(p.filterCountries.excludedCountries, common.userCountry.value) === true ||
            parseAndFind(p.filterStags.includedStags, common.stag.value) === false ||
            parseAndFind(p.filterStags.excludedStags, common.stag.value) === true
          )
      );
  });

  /**
   * Get current pages, with max specificity by stag and country
   */

  const currentPage = computed(
    () =>
      filteredPages.value.find(
        (p) =>
          parseAndFind(p.filterCountries.includedCoutries, common.userCountry.value) &&
          parseAndFind(p.filterStags.includedStags, common.stag.value)
      ) ||
      filteredPages.value.find(
        (p) =>
          !parseAndFind(p.filterCountries.includedCoutries, common.userCountry.value) &&
          parseAndFind(p.filterStags.includedStags, common.stag.value)
      ) ||
      filteredPages.value.find(
        (p) =>
          parseAndFind(p.filterCountries.includedCoutries, common.userCountry.value) &&
          !parseAndFind(p.filterStags.includedStags, common.stag.value)
      ) ||
      filteredPages.value.find(
        (p) =>
          !parseAndFind(p.filterCountries.includedCoutries, common.userCountry.value) &&
          !parseAndFind(p.filterStags.includedStags, common.stag.value)
      ) ||
      null
  );

  const videoBanner = computed(() => {
    const template = currentPage.value?.template;

    if (common.isMobile.value && template?.bannerMobile.mime.includes('video')) {
      return template.bannerMobile;
    }

    if (
      (common.isTablet.value || common.isDesktop.value) &&
      template?.bannerDesktop.mime.includes('video')
    ) {
      return template.bannerDesktop;
    }
    return null;
  });

  const bannerDesktop = computed(() => {
    const template = currentPage.value?.template;

    if (
      template?.bannerDesktop.mime?.includes('image') &&
      (common.isDesktop.value || common.isTablet.value || common.isMobile.value)
    ) {
      return template.bannerDesktop || null;
    }

    return null;
  });

  const bannerMobile = computed(() => {
    const template = currentPage.value?.template;
    if (template?.bannerMobile.mime?.includes('image') && common.isMobile.value) {
      return template.bannerMobile || null;
    }

    return null;
  });

  const bannerCssVariables = computed(
    (): {
      '--bg-img-desktop'?: string;
      '--bg-img-mobile'?: string;
    } => {
      const css = {};
      if (common.isMobile.value && !videoBanner.value) {
        css['--bg-img-mobile'] = `url(${bannerMobile.value?.url || ''})`;
        css['--bg-img-desktop'] = `url(${bannerDesktop.value?.url || ''})`;
      }
      if ((common.isTablet.value || common.isDesktop.value) && !videoBanner.value) {
        css['--bg-img-desktop'] = `url(${bannerDesktop.value?.url || ''})`;
      }
      return css;
    }
  );

  const logoImg = computed(() => {
    const template = currentPage.value?.template;
    return template?.logo || null;
  });

  /**
   * Getting data for promo page loader
   */
  const promoLoader = computed((): PromoLoaderInterface => {
    const template = currentPage.value?.template;

    return {
      loadingText: template?.loading || '',
      successText: template?.loadingSuccess || '',
      welcomeText: template?.welcome || '',
      loadingIcon: template?.loadingIcon?.url || '',
    };
  });

  const onlyCrypto = computed(() => {
    const template = currentPage.value?.template;

    return !!template.onlyCrypto;
  });

  const onlyFiat = computed(() => {
    const template = currentPage.value?.template;

    return !!template.onlyFiat;
  });

  const currency = computed((): string | null => {
    const template = currentPage.value?.template;

    return template?.currency?.currencyCode || null;
  });
  const selectedCurrency = ref('');

  const secondTitle = computed((): string | null => {
    const template = currentPage.value?.template;

    const titleParams = template?.secondTitleMulti;

    if (titleParams && Array.isArray(titleParams)) {
      const country = common.userCountry.value?.toLowerCase() || '';
      const userCurrency = selectedCurrency.value?.toLowerCase() || currency.value || '';

      if (titleParams?.length) {
        return (
          titleParams.find((b) => {
            return (
              b.country?.toLowerCase() === country && b.currency?.toLowerCase() === userCurrency
            );
          })?.value ||
          titleParams.find((b) => {
            return b.country?.toLowerCase() === country && !b.currency;
          })?.value ||
          titleParams.find((b) => {
            return b.currency?.toLowerCase() === userCurrency && !b.country;
          })?.value ||
          titleParams.find((b) => {
            return !b.currency && !b.country;
          })?.value ||
          null
        );
      } else {
        return null;
      }
    }

    return template?.secondTitle || null;
  });

  const theme = computed((): string | undefined => currentPage.value?.theme);

  const activeCurrencies = computed((): CurrencyMenuItemInterface[] => {
    const template = currentPage.value?.template;

    let result = strapi.currencies.value;

    if (!common.userCountry.value || template?.onlyCrypto) {
      result = result.filter(({ crypto }) => !!crypto);
    }

    if (template?.onlyFiat) {
      result = result.filter(({ crypto }) => !crypto);
    }

    result = result.filter(
      ({ exludedCountries }) =>
        !exludedCountries.length ||
        !exludedCountries.some((country) => country === common.userCountry.value)
    );

    return result;
  });

  return {
    filteredPages,
    isMiraxFrenchLand,
    isGoogleRegTemplate,
    lang,
    theme,
    currency,
    selectedCurrency,
    activeCurrencies,
    onlyCrypto,
    onlyFiat,
    promoLoader,
    logoImg,
    bannerCssVariables,
    secondTitle,
    videoBanner,
    bannerDesktop,
    bannerMobile,
    currentPage,
  };
}
