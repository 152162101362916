import { defineStore } from 'pinia';
import { saveStag } from '@/services/saveStag';

const stagToPureStag = (stag: string) => {
  const splittedStag = String(stag).split('_');
  if (splittedStag && splittedStag.length) {
    return splittedStag[0];
  }
  return '';
};

export const useStoreStag = defineStore('stag', {
  state: () => ({
    stag: '',
    wasRegisteredByPureStag: '',
    isStagToDelay: false,
  }),
  getters: {
    currentStag(state): string {
      if (state.stag && !state.stag.includes('_')) {
        return state.stag + '_noclickid';
      }
      return state.stag;
    },

    currentPureStag(): string {
      return stagToPureStag(this.currentStag || localStorage.stag || '');
    },

    wasRegisteredByPureStagGetter(state) {
      return state.wasRegisteredByPureStag;
    },

    isStagToDelayGetter(state) {
      return state.isStagToDelay;
    },
  },
  actions: {
    async checkStag() {
      const stagParams = window.location.href.match(/stag=(\d+(_[\d\w]+)?)/);
      if (stagParams && stagParams[1]) {
        this.stag = stagParams[1];
      } else if (localStorage && localStorage?.stag) {
        this.stag = localStorage.stag;
      }

      if (localStorage && this.currentStag) {
        localStorage.stag = this.currentStag;
      }
    },

    saveCurrentStag({ lang }) {
      saveStag({ stag: this.currentStag }, lang);
    },

    /*
    Sometimes we need to know if user had stag in past
    for example to show sign_up modal if user reloads page
    */
    checkPureStagByWhichWasRegistered() {
      if (localStorage && localStorage?.stag) {
        this.wasRegisteredByPureStag = stagToPureStag(localStorage.stag);
      }
      return '';
    },
  },
});
