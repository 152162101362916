import { defineStore } from 'pinia';
import { StrapiImageInterface } from '@/types/strapiImage.interface';

export interface IImageLink {
  id: string;
  img_src: StrapiImageInterface;
  alt?: string;
  link?: string;
  nofollow?: boolean;
  jsLink?: boolean;
  paymentMethodsLinkId?: string;
}

export interface IFooterLink {
  title?: string;
  linkType: 'internal';
  nofollow: boolean;
  link?: string;
  scrollToElement?: string;
  scrollToPosition?: number;
  modal?: 'string';
  strict?: boolean;
}

export const useStoreFooter = defineStore('footer', {
  state: () => ({
    partnersLink: [] as IImageLink[],
    paymentMethodsLink: [] as IImageLink[],
    informationLink: [] as IFooterLink[],
    partnersTitle: '' as string,
    responsibleGamingTitle: '' as string,
    copyright: '' as string,
    licenseText: '' as string,
    responsibleGamingLink: {} as IFooterLink,
    partnersLogo: {} as StrapiImageInterface,
    logo: {} as StrapiImageInterface,
    gamblers: [] as IImageLink[],
    requestPromise: null as Promise<any> | null,
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI({ data }) {
      const footerInfoLinks = data.informationLink.filter((el) => {
        if (
          el.link.includes('terms-and-conditions') ||
          el.link.includes('bonus-terms') ||
          el.link.includes('privacy-policy')
        ) {
          return true;
        }
        return false;
      });

      this.partnersLink = data.partnersLink;
      this.paymentMethodsLink = data.paymentMethodsLink;
      this.informationLink = footerInfoLinks;
      this.partnersTitle = data.partnersTitle;
      this.responsibleGamingTitle = data.responsibleGamingTitle;
      this.responsibleGamingLink = data.responsibleGamingLink;
      this.copyright = data.copyright;
      this.licenseText = data.licenseText;
      this.partnersLogo = data.partnersLogo;
      this.logo = data.logo;
      this.gamblers = data.gamblers;
    },
  },
});
