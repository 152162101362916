import axios from 'axios';

const strapi = {
  async get(dataName, params?) {
    try {
      const paramsString = params ? `${params}` : 'locale=en&populate=*';
      const url = `/strapi/${dataName}?${paramsString}`;

      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};

export { strapi };
