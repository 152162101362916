import { defineStore } from 'pinia';
import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { filterDateActiveItem } from '@/utils/filters';
export enum Enum_Race_Racelabel {
  Daily = 'Daily',
  Exclusive = 'Exclusive',
  Partner = 'Partner',
  Weekly = 'Weekly',
}
export interface RacesGettersInterface {
  activeRaces: RaceInterface[];
}

export interface RaceInterface {
  id: number;
  title: string;
  prizeTitle: string;
  raceLabel: Enum_Race_Racelabel;
  bannerSmall: StrapiImageInterface;
  bannerSmallMobile: StrapiImageInterface;
  position: number;
  ssId: string;
  startDate: Date | null;
  finishDate: Date | null;
  locale: string;
  groupFilter?: {
    includedGroups?: string | null;
  };
  bannerCardFront: StrapiImageInterface;
  bannerCardBack: StrapiImageInterface;
}

export const useStoreRaces = defineStore('races', {
  state: () => ({
    races: [] as RaceInterface[],
  }),
  getters: {
    activeRaces(state): RaceInterface[] {
      return state.races
        .filter(filterDateActiveItem)
        .filter((race) => !race.groupFilter?.includedGroups);
    },
  },
  actions: {
    SAVE_DATA_FROM_STRAPI(data) {
      this.races = data
        .map((race) => ({
          id: Number(race.id),
          title: race.title,
          prizeTitle: race.prizeTitle,
          raceLabel: race.raceLabel,
          bannerSmall: race.bannerSmall,
          bannerSmallMobile: race.bannerSmallMobile,
          bannerCardFront: race.bannerCardFront,
          bannerCardBack: race.bannerCardBack,
          position: +race.position,
          ssId: race.ssId,
          startDate: race.startDate ? new Date(race.startDate) : null,
          finishDate: race.finishDate ? new Date(race.finishDate) : null,
          locale: race.locale,
          groupFilter: race.groupFilter,
        }))
        .sort((a, b) => a.position - b.position);
    },
  },
});
