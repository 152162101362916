const gtmId = {
  '7bit': 'GTM-T9HVP5K',
  katsu: 'GTM-5MJ65XW',
  mirax: 'GTM-K82G9CD',
};

export const gtmByHost = (host: string) => {
  const currentHost = Object.keys(gtmId).find((key) => host.includes(key));

  return currentHost ? gtmId[currentHost] : gtmId['7bit'];
};
