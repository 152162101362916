import { StrapiImageInterface } from '~/types/strapiImage.interface';

const strapiImgUrl = `https://strapi-7bit-bucket.s3.us-west-1.amazonaws.com/`;
const newUrl = `/cdn/`;

/**
 * Method for parse strapi image interface and replace cdn url if needed
 *
 * @param imageData - object with data, loaded from Strapi
 */
export const imgParser = (imageData: any): StrapiImageInterface => {
  const result = {} as StrapiImageInterface;

  result.id = Number(imageData?.data?.id || imageData?.id);
  result.url = imageData?.data?.url || imageData?.url || '';
  result.width = imageData?.data?.width || imageData?.width || 0;
  result.height = imageData?.data?.height || imageData?.height || 0;
  result.mime = imageData?.data?.mime || imageData?.mime || '';

  if (process.env.S3_ORIGINAL_HOST !== 'true') {
    result.url = result.url.replace(strapiImgUrl, newUrl);
  }

  return result;
};
