import { computed } from 'vue';
import { useStoreLanguage } from '@/store/abstract/language';
import { LangsType } from '@/types/pages.types';

export function useDataLanguageStore() {
  const languageStore = useStoreLanguage();

  const language = computed((): LangsType => languageStore.language);

  const langPrefix = computed(() => languageStore.langPrefix);

  const setLocaleByCountry = () => languageStore.setLocaleByCountry();

  return {
    language,
    langPrefix,
    setLocaleByCountry,
  };
}
