import { defineStore } from 'pinia';

export const useStoreButtonTitles = defineStore('buttonTitles', {
  state: () => ({
    signup: '',
    learnMore: '',
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI({ data }) {
      this.signup = data.signup;
      this.learnMore = data.learnMore;
    },
  },
});
