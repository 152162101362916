export enum ESeonStatus {
  approve = 'APPROVE',
  review = 'REVIEW',
  decline = 'DECLINE',
}

export interface ISeonResponse {
  data: {
    data: {
      id: string;
      state: ESeonStatus;
      fraud_score: number;
      bin_details: any;
      version: string;
      applied_rules: {
        id: string;
        name: string;
        operation: string;
        score: number;
      }[];
      device_details: any;
      calculation_time: number;
      seon_id: number;
      ip_details: any;
      email_details: {
        email: string;
        score: number;
        deliverable: boolean;
        domain_details: {
          domain: string;
          tld: string;
          registered: boolean;
          created: string;
          updated: string;
          expires: string;
          registrar_name: string;
          registered_to: any;
          disposable: boolean;
          free: boolean;
          custom: boolean;
          dmarc_enforced: boolean;
          spf_strict: boolean;
          valid_mx: boolean;
          accept_all: boolean;
          suspicious_tld: boolean;
          website_exists: boolean;
        };
        account_details: any;
        breach_details: any;
        id: string;
        history: {
          hits: number;
          customer_hits: number;
          first_seen: number;
          last_seen: number;
        };
        flags: any[];
      };
      phone_details: any;
    };
    error: any;
    success: boolean;
  };
}
