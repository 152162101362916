import * as DeviceDetect from 'mobile-device-detect';
import { defineStore } from 'pinia';
import { LoadingStatusEnum } from '../../types/loadingStatus.enum';
import { PlatformTypeEnum } from '../../types/platformTypes.enum';

export const useStorePlatform = defineStore('platform', {
  state: () => ({
    widthMax: {
      mobile: 700,
      tablet: 975,
    },
    screen: { width: NaN, height: NaN },
    platform: PlatformTypeEnum.unknown as PlatformTypeEnum,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
    platformChecked: false as boolean,
    needResizeCheck: true as boolean,
    resizeListener: false as boolean,
    resizeInterval: undefined as number | undefined,
  }),
  getters: {
    /**
     * Method for getting platform status if check mobile device
     **/
    isMobile(): boolean {
      return this.platformGetter === PlatformTypeEnum.mobile;
    },

    /**
     * Method for getting platform status if check tablet device
     **/
    isTablet(): boolean {
      return this.platformGetter === PlatformTypeEnum.tablet;
    },

    /**
     * Method for getting platform status if check desktop device
     **/
    isDesktop(): boolean {
      return this.platformGetter === PlatformTypeEnum.desktop;
    },

    /**
     * Method for getting platform status if check desktop device
     **/
    platformGetter(state): PlatformTypeEnum {
      if (
        state.platform === PlatformTypeEnum.mobile ||
        state.screen.width <= state.widthMax.mobile
      ) {
        return PlatformTypeEnum.mobile;
      } else if (
        state.platform === PlatformTypeEnum.tablet ||
        (state.screen.width >= state.widthMax.mobile && state.screen.width <= state.widthMax.tablet)
      ) {
        return PlatformTypeEnum.tablet;
      } else if (state.platform === PlatformTypeEnum.desktop && state.screen.width > 975) {
        return PlatformTypeEnum.desktop;
      }

      return PlatformTypeEnum.unknown;
    },

    /**
     * Method for getting orientation of device
     **/
    isPortraitOrientation(state): boolean {
      return state.screen.width > state.screen.height;
    },

    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },

    screenWidth(state): number {
      return state.screen.width;
    },

    screenHeight(state): number {
      return state.screen.height;
    },
  },
  actions: {
    SET_PLATFORM(platform: PlatformTypeEnum): void {
      if (this.platform !== platform) {
        this.platform = platform;
      }
    },
    SET_STATUS(status: LoadingStatusEnum): void {
      if (this.status !== status) {
        this.status = status;
      }
    },
    SET_SCREEN_SIZE(size: { width: number; height: number }): void {
      this.screen.width = size.width;
      this.screen.height = size.height;
    },
    /**
     * function for set platform data to the state from plugin 'mobile-device-detect'
     * Plugin documentation: https://github.com/duskload/mobile-device-detect/blob/master/README.md
     **/
    async checkPlatform(force?: boolean): Promise<any> {
      if (this.platformChecked && !force) {
        return;
      }

      this.platformChecked = true;

      await this.checkScreenSize();

      if (DeviceDetect.isMobileOnly) {
        this.SET_PLATFORM(PlatformTypeEnum.mobile);
      } else if (DeviceDetect.isTablet) {
        this.SET_PLATFORM(PlatformTypeEnum.tablet);
      } else {
        this.SET_PLATFORM(PlatformTypeEnum.desktop);
      }
      this.SET_STATUS(LoadingStatusEnum.loaded);
    },

    checkScreenSize() {
      this.needResizeCheck = false;

      this.SET_SCREEN_SIZE({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (!this.resizeListener) {
        this.resizeListener = true;

        clearInterval(this.resizeInterval);
        this.resizeInterval = window.setInterval(() => {
          if (this.needResizeCheck) {
            this.checkScreenSize();
          }
        }, 100);

        window.addEventListener('resize', () => (this.needResizeCheck = true));
      }
    },
  },
});
