import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';

export function useCommonStore() {
  const countryStore = useStoreCountry();

  const platformStore = useStorePlatform();
  const isDesktop = computed(() => platformStore.isDesktop);
  const isMobile = computed(() => platformStore.isMobile);
  const isTablet = computed(() => platformStore.isTablet);

  const isPortraitOrientation = computed(() => platformStore.isPortraitOrientation);

  const userCountry = computed((): string => countryStore.countryGetter);

  const route = useRoute();

  /**
   * Get transformed path variable
   */
  const path = computed(() => String(route.params.path).toLowerCase() || '');

  /**
   * Get Stag from url
   */
  const stag = computed(() => (route.query?.stag ? String(route.query?.stag).toLowerCase() : ''));

  const targetHost = computed((): string => {
    return '';
  });

  const dataPlatformAttr = computed(() => {
    return {
      'data-platform-mobile': isMobile.value || null,
      'data-platform-tablet': isTablet.value || null,
      'data-platform-desktop': isDesktop.value || null,
    };
  });

  return {
    path,
    dataPlatformAttr,
    isDesktop,
    isMobile,
    isTablet,
    isPortraitOrientation,
    userCountry,
    targetHost,
    stag,
  };
}
