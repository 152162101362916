import axios from 'axios';

interface CountryResponse {
  data: {
    country: string;
  };
}

export async function getCountry(host: string): Promise<string> {
  const res = (await axios.get(`https://${host}`)) as CountryResponse;

  return res.data?.country ?? '';
}
