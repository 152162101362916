export const filterDateActiveItem = (item: {
  startDate?: Date | null;
  finishDate?: Date | null;
}): boolean => {
  if (item.startDate && new Date(item.startDate).getTime() > Date.now()) {
    return false;
  }

  if (item.finishDate && new Date(item.finishDate).getTime() < Date.now()) {
    return false;
  }

  return true;
};
