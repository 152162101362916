import axios from 'axios';

export async function getRegForm(host: string, lang: string) {
  const response = await axios.get(`https://${host}/reg-forms`, {
    params: {
      lang,
    },
  });

  return response.data[0];
}
