import { defineStore } from 'pinia';
import { getProfileForm } from '@/services/profileForm';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { StrapiProfileFormInterface } from '~/types/strapiProfileForm.interface';

export const useStoreProfileForm = defineStore('profileForm', {
  state: () => ({
    data: {} as StrapiProfileFormInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    formData(state): StrapiProfileFormInterface {
      return state.data;
    },
    isLoading(state): boolean {
      return state.status === LoadingStatusEnum.loading;
    },
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    /**
     * function for load profile form from strapi
     *
     * @param context - context object
     * @param options - request options
     **/
    async load(lang: string): Promise<void> {
      if (!this.isLoaded) {
        this.status = LoadingStatusEnum.loading;
      }

      // const host = `7bitcasino.com/dinamic-content`;
      const host = `${
        location.host === 'localhost' || location.host === 'local.7bitcasino.com'
          ? '7bitcasino.com'
          : location.host
      }/dinamic-content`;
      const [data] = await getProfileForm(host, lang);

      if (data) {
        this.data = data;
        this.status = LoadingStatusEnum.loaded;
      } else {
        this.status = LoadingStatusEnum.error;
      }
    },
  },
});
