import { defineStore } from 'pinia';

export interface ISignUpCloseConfirm {
  title: string;
  textBeforeVariable: string;
  btnBackToSignup: string;
  btnClose: string;
  textAfterVariable: string;
}

export const useStoreLandingComponents = defineStore('landingComponents', {
  state: () => ({
    signUpCloseConfirm: {} as ISignUpCloseConfirm,
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI({ data }) {
      this.signUpCloseConfirm = data.signUpCloseConfirm;
    },
  },
});
