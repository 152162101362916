import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { updateLocale } from '@/services/updateLocale';

export const useStoreSsUpdateLocale = defineStore('ssUpdateLocale', {
  state: () => ({}),
  actions: {
    async patch() {
      try {
        const languageStore = useStoreLanguage();
        const lang = languageStore.languageGetter;
        updateLocale(lang);
      } catch (e) {
        console.error('locale update network error');
      }
    },
  },
});
