import { defineStore } from 'pinia';

export const useStoreSnippet = defineStore('snippet', {
  state: () => ({
    data: {} as Record<string, number>,
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI(data) {
      if (data.snippets) {
        this.data = data.snippets.reduce((result: Record<string, number>, item: any) => {
          if (item && item.currency) {
            result[item.currency] = Number(item.eurEquivalent);
          }
          return result;
        }, {} as Record<string, number>);
      }
    },
  },
});
