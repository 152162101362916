import { ref, readonly } from 'vue';
import { ModalType } from '@/types/modal.types';

const showNodal = ref('');

const openModal = function (modalName: ModalType = 'default'): void {
  showNodal.value = modalName;
};

const closeModal = function (): void {
  showNodal.value = '';
};

export default function usePromoModal() {
  return {
    show: readonly(showNodal),
    openModal,
    closeModal,
  };
}
