/**
 * Get cookie value by key from document.cookie
 */
export const getCookieValue = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
};

/**
 * Get domain name without subdomains using JavaScript
 */
export const rootDomain = (hostname: string) => {
  let parts = hostname.split('.');
  if (parts.length <= 2) {
    return hostname;
  }

  parts = parts.slice(-3);
  if (['co', 'com'].includes(parts[1])) {
    return parts.join('.');
  }

  return parts.slice(-2).join('.');
};
